import {faLink} from '@fortawesome/free-solid-svg-icons';
import {useI18n} from '@halp/foundation/i18n';
import {Button, Icon, Stack, useToast, Heading} from '@halp/ui';

export function ReferralLinkButton({
	campaignSlug,
	orgHandle,
}: {
	campaignSlug: string;
	orgHandle: string;
}) {
	const addToast = useToast();
	const {t} = useI18n();

	const campaignUrl =
		campaignSlug && orgHandle
			? `https://${orgHandle}.halp.co/signup/${campaignSlug}`
			: '';

	const displayUrl = campaignUrl.replace('https://', '');

	return (
		<Button
			onClick={() => {
				navigator.clipboard.writeText(campaignUrl);
				addToast({
					type: 'success',
					message: t('ambassador.referral_link_copied'),
				});
			}}
			thin
			padding="md"
			color="secondary"
		>
			<Stack spacing="sm" fullWidth>
				<Icon icon={faLink} size="lg" />
				<Stack direction="column" spacing="none" alignItems="center" fullWidth>
					<Heading type="h4" align="center">
						{t('ambassador.your_referral_link')}
					</Heading>
					<Heading type="h5" color="grey">
						{displayUrl}
					</Heading>
				</Stack>
			</Stack>
		</Button>
	);
}
